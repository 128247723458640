<template>
  <el-container>
<!--    <el-aside width="124px">-->
<!--      <sidebar></sidebar>-->
<!--    </el-aside>-->
    <el-container>
<!--      <el-header>-->
        <ecare-header style="position: fixed;width: 100%;z-index: 999;background-color: #e2eff8"></ecare-header>
<!--      </el-header>-->
<!--      <el-main>-->
        <div >
          <router-view/>
        </div>
<!--      </el-main>-->
      <el-footer height="40px" style="width: 100%;margin-top: -5vh">
        <div style="padding:10px;text-align: center; color: #909399;background-color: #c0daf1">
          Copyright ©2023 乌鲁木齐经济技术开发区高铁枢纽综合投资（集团）有限公司. 版权所有. v 2310040134
        </div>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from "@/ecareLay/compoments/Sidebar";
import AppMain from "@/ecareLay/compoments/AppMain";
import EcareHeader from "@/ecareLay/compoments/Header";
export default {
  name: "index",
  components: {AppMain, Sidebar,EcareHeader}
}
</script>

<style>
.el-footer {
  padding: 0 0;
  flex-shrink: 0;
}
</style>
