import dayjs from 'dayjs'

export function dateFormat(val){
    if (val!=null){
       let  date =dayjs(val).format('YYYY-MM-DD HH:mm:ss')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function clickInDateFormat(val){
    if (val!=null){
        let  date =dayjs(val).format('YYYY-MM-DD HH:mm')
        if (date==='2099-01-01 00:00:00'){
            return "长期"
        }
        return date
    }else {
        return "暂无数据"
    }
}

export function birthDateFormat(val){
    if (val!=null){
        return dayjs(val).format('YYYY-MM-DD')
    }else {
        return "未填写"
    }
}

export function alertType(value) {
    console.log(value)
    let type = "";
    switch (value){
        case 1:
            type = '超出';
            break;
        case 2:
            type = '上线';
            break;
        case 3:
            type = '离线';
            break;
        case 4:
            type = '其它';
            break;
        default:
            break;
    }
    return type;
}

export function eqType(value) {
    console.log(value)
    let type = "";
    switch (value){
        case "AMMETER":
            type = '电表';
            break;
        case "WATER_METER":
            type = '水表';
            break;
        case "MONITOR":
            type = '监控';
            break;
        case "PDA":
            type = 'PDA';
            break;
        case "PATROL_LABEL":
            type = '标签';
            break;
        case "ENTRANCE_GUARD":
            type = '门禁';
            break;
        default:
            break;
    }
    return type;
}

export default {
    install(Vue) {
        Vue.filter('dateFormat', dateFormat)
        Vue.filter('birthDateFormat', birthDateFormat)
        Vue.filter('clickInDateFormat', clickInDateFormat)
        Vue.filter('alertType', alertType)
        Vue.filter('eqType', eqType)
    }
}