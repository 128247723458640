import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'

import "./permission";

import components from "@/components";
components(Vue)

import api from "@/api/index"; // 导入api接口

import Element from 'element-ui';
import "@/styles/element-variables.scss";

Vue.use(Element, { size: 'small', zIndex: 3000 });

import "@/styles/index.scss";

//自定义拦截器
import * as filters from './utils/filter.js'

Vue.config.productionTip = false

Vue.prototype.$api = api;

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
