import axios from 'axios'
import router from '../router'
import {Message } from 'element-ui'
// import store from '@/store'
import {getToken, removeToken, setToken} from '@/utils/auth'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: "https://gateway.wlmqgtzs.com", // url = base url + request url
  // withCredentials:x true, // send cookies when cross-domain requests
  timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (getToken()){
        config.headers['UchungToken'] = getToken()
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    if(response.headers['UchungToken']!=undefined){
        setToken(response.headers['UchungToken'])
    }
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      if (res.code == 500){
          removeToken();
          router.push(`/login?redirect=`+router.currentRoute.fullPath)
      }else {
          return Promise.reject(res)
      }
    } else {
      return res
    }
  },
  error => {
    console.log(error.response)
    if (error.response.status==401){
        return Promise.reject({code:401,data:null,msg:"暂无权限"})
    }else {
        // Message({
        //     message: error.message,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
  }
)

export default service