export function addCookie(objName, objValue, objHours) {
    var str = objName + "=" + escape(objValue);
    if (objHours > 0) {
        var date = new Date();
        var ms = objHours * 3600 * 1000;
        date.setTime(date.getTime() + ms);
        str += "; expires=" + date.toGMTString();
    }
    document.cookie = str;
}

export function setCookie(c_name, value, expiredays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + expiredays);
    document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString() + ";path=/;domain=.wlmqgtzs.com");
}

export function processData(data) {
    // addCookie(data.TokenKey, data.token);
    setCookie(data.TokenKey, data.token, 7);
}

export function remove() {
    let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if(keys) {
        for (let i = keys.length; i--;) {
            document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
            document.cookie = keys[i] + '=0;path=/;domain=.wlmqgtzs.com;expires=' + new Date(0).toUTCString();
        }
    }
}
