<template>
  <Card :title="title">
    <template #left>
      <slot name="title-left"></slot>
    </template>
    <template #right>
      <slot name="title-right"></slot>
    </template>
    <el-table :data="data || []" class="table" v-loading="loading">
      <el-table-column v-if="hasIndex" width="50">
        <template slot-scope="scope">
          {{hasSum && scope.$index === data.length - 1 ? "" : scope.$index + 1}}
        </template>
      </el-table-column>
      <el-table-column
          v-for="(column, index) in columns"
          :key="column.dataIndex"
          :label="column.title"
          :prop="column.dataIndex"
          :current-row-key="rowKey"
          :width="column.width"
          :type="column.slot=='expand'?'expand':''"
          :show-overflow-tooltip="column.overflow||true"
          :align="column.isAmount ? 'right' : column.align"
      >
        <template slot-scope="scope">
          <div v-if="column.slot">
            <slot :name="column.slot" :scope="{[column.dataIndex]: scope.row[column.dataIndex],index: scope.$index,row: scope.row,}"/>
          </div>
          <span v-else-if="column.isAmount">
            {{
              scope.row[column.dataIndex] !== null &&
              scope.row[column.dataIndex] !== undefined
                  ? (scope.row[column.dataIndex] / 100).toFixed(2)
                  : ""
            }}
          </span>
          <span v-else-if="column.customRender">
            {{
              column.customRender(scope.row[column.dataIndex], scope.row, index)
            }}
          </span>
          <span v-else-if="column.imageWidth">
            <el-image :style="'width: '+column.imageWidth+'; height: '+column.imageWidth" :src="scope.row[column.dataIndex]"></el-image>
          </span>
          <span v-else-if="column.statusTag">
            <el-tag :type="scope.row[column.dataIndex]?'':'danger'">{{ scope.row[column.dataIndex]?column.statusTag[0]:column.statusTag[1]}}</el-tag>
          </span>
          <span v-else-if="column.options">
            {{ getOptionsValue(scope.row[column.dataIndex], column.options) }}
          </span>
          <span v-else>
            {{ scope.row[column.dataIndex] }}
          </span>
        </template>
      </el-table-column>
      <template #empty>
        <div class="empty">
          <img src="../assets/img/empty.png" />
          <div>暂无数据</div>
        </div>
      </template>
    </el-table>

    <el-pagination
        v-if="data && data.length !== 0 && usePagination"
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.pageNum"
        :page-sizes="pagination.pageSizeOptions"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total"
    >
    </el-pagination>
  </Card>
</template>

<script>
import Card from "@/components/Card";
export default {
  name: "BaseTable",
  components: {Card},
  props: {
    title: {
      type: String,
      default:"列表数据"
    },
    usePagination: {
      type: Boolean,
      default: true,
    },
    // 是否mount时获取数据
    immediate: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    getData: {
      type: Function,
    },
    rowKey: {
      default: "id",
    },
    // 列表是否展示序号列
    hasIndex: {
      type: Boolean,
      default: false,
    },
    hasExpand: {
      type: Boolean,
      default: false,
    },
    // 是否有总计
    hasSum: {
      type: Boolean,
      default: false,
    },
    requestFun: {
      type: Function,
    },
    searchForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pagination: {
        size: "small",
        total: 0,
        pageSize: 10,
        pageNum: 1,
        pageSizeOptions: ["10", "30", "45", "60", "75", "90", "500"],
      },
      total: 0,
      data: [],
      loading: false,
      // 多选选择的行
      selected: [],
      dragSourceIndex: null,
    };
  },
  mounted() {
    if (this.immediate) {
      this.getTableData();
    }
  },
  methods:{
    async defaultGetData(
        pages = {
          pageNum: 1,
          pageSize: 10,
        }
    ) {
      const result = await this.requestFun({
        ...pages,
        ...this.searchForm,
      });
      return {
        data: result.data.list || [],
        total: result.data.total,
      };
    },
    async getTableData(pageNum = this.pagination.pageNum) {
      this.pageNum = pageNum;
      this.selected = [];
      this.pagination.pageNum = pageNum;
      this.loading = true;
      const getDataFun = this.getData || this.defaultGetData;
      getDataFun({
        pageNum,
        pageSize: this.pagination.pageSize - this.hasSum,
      }).then(({ data, total, callbackFn }) => {
            this.data = data;
            this.total = total - 0;
            this.pagination.total = total;
            callbackFn && callbackFn();
      }).finally(() => {
            this.loading = false;
      });
    },
    // 修改每页数量
    handleSizeChange(val) {
      this.pagination.pageNum = 1;
      this.pagination.pageSize = val;
      this.getTableData();
    },
    // 切换页面
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.getTableData();
    },
    // 当列是选项时，获取对应值
    getOptionsValue(value, options) {
      return options.find((item) => item.value === value).label;
    },
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
}
.table {
  margin: 0 20px;
  width: auto;
}
::v-deep thead tr {
  background: #91d4f8;
}
::v-deep .el-table th.el-table__cell {
  color: #444;
  background: #91d4f8;
}
.empty {
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #666666;
  line-height: 33px;
  img {
    margin-bottom: 20px;
    width: 136px;
    height: 88px;
  }
}
</style>
