import Vue from "vue";
import VueRouter from "vue-router";
import ecareLay from "@/ecareLay/index.vue";

Vue.use(VueRouter);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;

// push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

export const commonRouters = [
    {
        path: "/login",
        hidden: true,
        meta: { title: "登录", icon: "el-icon-ed-shouye", affix: true },
        component: () => import(/* webpackChunkName: "login" */ "../pages/login/index.vue"),
    },
    {
        path: "/",
        component: ecareLay,
        redirect: "/index",
        children: [
            {
                path: "/index",
                meta: { title: "首页", icon: "el-icon-ed-shouye", affix: true },
                component: () => import(/* webpackChunkName: "login" */ "../pages/home/index.vue"),
            }
        ],
    },
]

const createRouter = () =>
    new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes: commonRouters,
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;