import request from "@/utils/request";
function getUrl(url) {
    return "/test-api/" + url;
}

export default {
    addUcElectricityBilling(data){
        return request({
            url: getUrl("billing/addUcElectricityBilling"),
            method: "post",
            data,
        })
    },
    updateUcElectricityBilling(data){
        return request({
            url: getUrl("billing/updateUcElectricityBilling"),
            method: "post",
            data,
        })
    },
    deleteUcElectricityBilling(data){
        return request({
            url: getUrl("billing/deleteUcElectricityBilling"),
            method: "post",
            data,
        })
    },
    getUcElectricityBilling(data){
        return request({
            url: getUrl("billing/getUcElectricityBilling"),
            method: "post",
            data,
        })
    },
    getUcElectricityBillingPage(data){
        return request({
            url: getUrl("billing/getUcElectricityBillingPage"),
            method: "post",
            data,
        })
    },
}