<!--侧边栏菜单展示-->
<template>

</template>

<script>
export default {
  name: "Sidebar"
}
</script>

<style scoped>
.sidebar-container{
  min-height: 100vh;
  width: 124px;
  background-color:#202033
}
.sidebar-avatar{
  height: 80px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center
}
.scrollbar-wrapper {
  overflow-x: hidden !important;
}
</style>